import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/compliance/compliance-banner.png'
import {
  faUserShield,
  faExclamationTriangle,
  faProjectDiagram,
  faBalanceScale,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: <>COMPLIANCE</>,
    description: (
      <>
        Vamos estabelecer os processos de Compliance em sua organização, com um
        time altamente capacitado e seguindo boas práticas de normas de gestão
        reconhecidas internacionalmente (ISO 37301, 37001, 31000, 27001).
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `Unimed Botucatu`,
    text: `Governança em adequação a resolução normativa RN 443/2019`,
  },
  {
    title: `P4 Engenharia`,
    text: `Implementação de LGPD e todos os processos de Compliance`,
  },
  {
    title: `Trapisa Engenharia`,
    text: `Implementação de Controladoria e projeto de Compliance`,
  },
]

const Compliance: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faBalanceScale,
      title: `Gestão de Compliance`,
      description: (
        <>
          Implementação de ponta-a-ponta. Desde a criação do Comitê de
          Compliance, até o estabelecimento de todos os processos, políticas,
          procedimentos específicos, e treinaremos toda a equipe da sua
          organização para sustentabilidade da nova área, com base nas melhores
          práticas da ISO 37301.
        </>
      ),
    },
    {
      faIcon: faExclamationTriangle,
      title: `Gestão de Riscos`,
      description: (
        <>
          Faremos o levantamento de todos os riscos de processos e estratégicos,
          definiremos os proprietários de cada risco, e na sequência iremos
          elaborar a Matriz de Riscos com suas classificações, seus controles e
          indicadores p/
          <a
            href="https://blog.valoremelhoria.com.br/2021-05-05-gest%C3%A3o-de-riscos/"
            target="_blank"
            rel="noopener"
            className={styles.innerTextLink}
          >
            gestão dos riscos
          </a>
          , com base nas melhores práticas da ISO 31000.
        </>
      ),
    },
    {
      faIcon: faUserShield,
      title: `Anticorrupção e Antisuborno`,
      description: (
        <>
          Iremos implementar as boas práticas da norma ISO 37001, com o objetivo
          de combater atos ilícitos por meio da divulgação de uma cultura de
          integridade, transparência e conformidade junto às leis e
          regulamentações aplicáveis.
        </>
      ),
    },
    {
      faIcon: faProjectDiagram,
      title: `ESG`,
      description: (
        <>
          Iremos fazer um diagnóstico, e na sequência a definição e
          implementação de
          <a
            href="https://blog.valoremelhoria.com.br/2021-05-29-esg-environmental-social-and-governance-e-sua-import%C3%A2ncia-para-atrair-novos-investidores/"
            target="_blank"
            rel="noopener"
            className={styles.innerTextLink}
          >
            indicadores ESG
          </a>{' '}
          (Ambiental / Social e Governança) que estão diretamente relacionados
          às ações empresariais, com a finalidade de mostrar o compromisso de
          sua empresa com esses temas.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Diagnóstico',
      description: (
        <>
          Faremos um levantamento do <strong> CONTEXTO DA ORGANIZAÇÃO</strong>,
          para entender seus fatores internos e externos, partes interessadas,
          políticas, procedimentos, riscos.
        </>
      ),
    },
    {
      count: 2,
      title: 'Gestāo da Mudança',
      description: (
        <>
          Através da nossa metodologia de implantação, estabeleceremos{' '}
          <strong>PLANO DE AÇÃO 5W2H</strong> para implementação de todo o
          projeto, e alocaremos uma equipe de especialistas para levar sua
          organização até o resultado planejado
        </>
      ),
    },
    {
      count: 3,
      title: 'Treinamentos',
      description: (
        <>
          Forneceremos <strong>TREINAMENTOS MULTIDISCIPLINARES</strong> para o
          dono do processo de Compliance/Riscos, e também para os colaboradores.
        </>
      ),
    },
    {
      count: 4,
      title: 'Ágil',
      description: (
        <>
          Faremos uma <strong> GESTÃO ÁGIL</strong> do projeto, de forma a
          entregar valor durante todas as etapas, até a sua finalização.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Consultoria em Gestão de Compliance - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Reduzir a incidência de fraudes e desconformidades que geram desvios de recursos, riscos de sanções legais, perdas financeiras e reputação, e aumentar a qualidade das decisões dentro da organização, reduzindo o custo operacional"
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} isNumericCases={true} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default Compliance
